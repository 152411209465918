import React, { Component } from 'react';
import classnames from 'classnames';

import styles from './Text.module.scss';

export default class Text extends Component {
  render() {
    const { className, style } = this.props;
    
    return (
      <span className={classnames(styles.text, className)} style={style}>
        {this.props.children}
      </span>
    );
  }
}
