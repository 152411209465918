import React, { Component } from 'react';
import classnames from 'classnames';

import AppContainer from './LM/Component/AppContainer';
import View from './LM/Component/View';
import Text from './LM/Component/Text';

import styles from './App.module.scss';
import logo from './logo.svg';
import appStore from './assets/images/app-store-black.png';
import googlePlay from './assets/images/google-play-black.png';
import img1 from './assets/images/1.png';
import img2 from './assets/images/2.png';
import img3 from './assets/images/3.png';
import img4 from './assets/images/4.png';

class App extends Component {
  render() {
    const headerStyle = classnames(styles.headerContainer);
    const featureStyle = classnames(styles.featureContainer);
    return (
      <AppContainer className={styles.container}>
        <View className={classnames(headerStyle, styles.bg1)} style={{backgroundColor: '#0f60db', height: '10vmin'}}>
          <img src={logo} className={styles.logo} alt="logo" />
          <Text style={{fontSize: '2em'}}>미세는 처음이라</Text>
          <Text style={{marginRight: '3vw'}}>Lab.Monade</Text>
        </View>
        <View className={classnames(featureStyle, styles.bg2)} style={{backgroundColor: '#27aedb', height: '50vmin'}}>
          <View className={classnames(styles.innerContainer)}>
            <View>
              <Text>WHO기준 & 날씨와 미세먼지를 한눈에 보기 쉽게!</Text>
              <Text>미세는 처음이라! 미처!</Text>
              <a href="https://itunes.apple.com/kr/app/%EB%AF%B8%EC%84%B8%EB%8A%94-%EC%B2%98%EC%9D%8C%EC%9D%B4%EB%9D%BC/id1447101772?mt=8">
                <img src={appStore} style={{height: '5vmin', margin: 5}} alt="App Store" />
              </a>
              <a href="https://play.google.com/store/apps/details?id=com.labmonade.firstmise">
                <img src={googlePlay} style={{height: '5vmin', margin: 5}} alt="App Store" />
              </a>
            </View>
            <img src={img1} style={{height: '35vmin', margin: 5}} alt="ss1" />
          </View>
        </View>
        <View className={classnames(featureStyle, styles.bg3)} style={{backgroundColor: '#55c6b9', height: '50vmin'}}>
          <View className={classnames(styles.innerContainer)}>
            <img src={img2} style={{height: '35vmin', margin: 5}} alt="ss1" />
            <View>
              <Text>실시간 미세먼지와 날씨 정보를 한눈에!</Text>
              <Text>숫자로 보여주는 다른 어플 들과는 다릅니다.</Text>
              <Text>직관전인 아이콘과 이미지를 이용하여 보여주는 앱!</Text>
              <Text>요즘 날씨는 미세먼지를 확인하셔야 합니다.</Text>
              <Text>미세는 처음이라가 여러분의 건강을 지켜 드리겠습니다!</Text>
            </View>
          </View>
        </View>
        <View className={classnames(featureStyle, styles.bg4)} style={{backgroundColor: '#0eb270', height: '50vmin'}}>
          <View className={classnames(styles.innerContainer)}>
            <Text>황사, 오존, 이산화질소, 일산화탄소, 이황산가스 실시간 측정 값</Text>
            <img src={img3} style={{height: '35vmin', margin: 5}} alt="ss1" />
          </View>
        </View>
        <View className={classnames(featureStyle, styles.bg5)} style={{backgroundColor: '#d14e17', height: '50vmin'}}>
          <View className={classnames(styles.innerContainer)}>
            <img src={img4} style={{height: '35vmin', margin: 5}} alt="ss1" />
            <Text>모든 측정소 초미세먼지 정보 제공 - 초미세먼지 값 미제공 측정소일 시, 가장 가까운 측정소 값으로 대체함</Text>
          </View>
        </View>
        <View className={classnames(featureStyle, styles.bg6)} style={{backgroundColor: '#c11d1d', height: '50vmin'}}>
          <View className={classnames(styles.innerContainer)}>
            <Text>Lab.Monade</Text>
            <View style={{alignItems: 'flex-start'}}>
              <Text>자료출처:</Text>
              <Text>- 환경부 한국환경공단</Text>
              <Text style={{marginLeft: 7}}>(에어코리아)</Text>
              <Text>- 기상청</Text>
            </View>
          </View>          
        </View>
      </AppContainer>
    );
  }
}

export default App;
