import React, { Component } from 'react';
import classnames from 'classnames';

import '../Global.scss';
import styles from './View.module.scss';

export default class View extends Component {
  render() {
    const { className, style } = this.props;
    
    return (
      <div className={classnames(styles.view, className)} style={style}>
        {this.props.children}
      </div>
    );
  }
}
